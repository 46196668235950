<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import InputTag from 'vue-input-tag';

import { VueEditor } from "vue2-editor";
//import Basic from "./basic";

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Site registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, InputTag, VueEditor /*Basic*/ },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Site registration",
      componentKey: 0,
      num_lat: 0.0,
      num_lng: 0.0,
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Sites",
          href: "/company/sites"
        },
        {
          text: "Site",
          active: true
        }
      ],
      form: {
        de_slogan: "Eval with sense.",
        de_city: "Ensenada, B.C. Mexico.",
        de_description: "THIS IS MY COMPANY, IT WAS CREATED BECAUSE OF THE NEED TO KEEP WELL FEED OF MY 3 KIDS.",
        de_emailContact: "contact@mycompany.com.mx",
        num_contactPhone: "+52 (646) 1114859",
        tags:["Consulting","Human Resource","Management","Personal Evaluation","Training","Management Audits"],
        history: "",
        industry: "",
        capabilities: "",
      },
      submitted: false,
      canEdit: true,
      isEditState: false,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      content: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
    };
  },
  validations: {
    form: {
      de_slogan: { required },
      de_city: { required },
      de_description: { required, minLength: minLength(100), maxLength: maxLength(200) },
      de_emailContact: { required, email },
      num_contactPhone: { required }
    }
  },
  watch:{
    lat(newLat, oldLat){
      if(newLat != oldLat){
        this.lat = newLat;
        this.forceRerenderMap();
      }
    },
    lng(newLng, oldLng){
      if(newLng != oldLng){
        this.lng = newLng;
        this.forceRerenderMap();
      }
    }
  },
  mounted() {
    if( this.$route.params.id ){
      console.log(this.$route.params.id);
      this.setSite();
    }else{
      console.log("ID no proporcionado");
    }
    this.setSite();
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        Swal.fire("Good job!", "Your site is saved now!", "success");
      }
    },
    setSite(){
      this.form.id_country              = "MEX";
      this.form.id_state                = "BC";
      this.form.de_address              = "CONOCIDA EN LA CIUDAD DE ENSENADA. COLONIA PUNTA BANDA III, EDIFICIO ROSA, SEGUNDO PISO, #373-3";
      this.form.de_emailRepresentant    = "a@a.com";
      this.form.de_emailHumanResources  = "b@b.com";
      this.form.de_emailContact         = "c@c.com";
      this.form.nm_siteName             = "MEXICO";
      this.form.sn_isCorporative        = true;

      this.form.history                 = this.text;
      this.form.industry                = this.content;
      this.form.capabilities            = this.text + " " + this.content;
      console.log(this.form);
    },
    setEditState(){
      this.isEditState = !this.isEditState;

      /*
      let toolbarSections = document.getElementsByClassName("ql-formats");
      console.log(toolbarSections);
      let listArr = Array.from(toolbarSections);

      listArr.forEach(item => console.log(item.children));
      */
    },


  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row ml-1 mb-2" v-if="canEdit">
      <button v-if="!isEditState" @click="setEditState" type="button" class="btn btn-sm btn-primary"><i class="ri-edit-fill"></i> Edit Company</button>
      <button v-if="isEditState"  @click="setEditState" type="button" class="btn btn-sm btn-success"><i class="ri-save-fill"></i> Save Company</button>
    </div>

    <div class="row" v-if="!isEditState">
      <div class="col-lg-12">
        <div class="email-leftbar card">
          <img src="@/assets/images/evalmatters-logo.png"/>
          <p class="card-title-desc text-center m-0"><b>Eval with sense</b></p>
          <p class="card-title-desc text-center m-0"><small>Ensenada, B.C. Mexico</small></p>

          <div class="card"></div>

          <div class="mail-list mt-3">
            <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>About</b></div>
            <p>THIS IS MY COMPANY, IT WAS CREATED BECAUSE OF THE NEED TO KEEP WELL FEED OF MY 3 KIDS</p>
            

            <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
            <p>
              <small><i class="far fa-envelope"></i> contact@mycompany.com.mx</small><br>
              <small><i class="fas fa-phone-alt"></i> +52 (646) 1114859</small><br><br>
              <small>Ensenada B.C., Mexico. Punta Banda Colony #373 Dept 3, 2nd floor.</small>
            </p>

            <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
            <span v-for="tag in form.tags" :vid-id="tag" :key="tag" class="badge ml-1 badge-success badge-pill">{{tag}}</span>
          </div>
        </div>

        <div class="card email-rightbar">
          <div class="card-body">
            <b-tabs content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">History</span>
                </template>
                <p v-html="form.history"></p>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Industry</span>
                </template>
                <p v-html="form.industry"></p>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Capabilities</span>
                </template>
                <p v-html="form.capabilities"></p>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Social Networks</span>
                </template>
                <p></p>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>


    <div class="row" v-if="isEditState">
      <div class="col-lg-12">
        <div class="email-leftbar card">
          <img src="@/assets/images/evalmatters-logo.png"/>
          <div class="form-group text-center m-1">
            <input
              class="form-control-sm"
              name="de_slogan"
              id="de_slogan"
              v-model="form.de_slogan"
              type="text"
              placeholder="Slogan company"
            />
          </div>
          <div class="form-group text-center m-1">
            <input
              class="form-control-sm"
              name="de_city"
              id="de_city"
              v-model="form.de_city"
              type="text"
              placeholder="City location"
            />
          </div>


          <div class="card"></div>

          <div class="mail-list mt-4">
            <div class="form-group mt-0">
              <label for="de_description">About</label>
              <textarea
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.form.de_description.$error }"
                name="de_description"
                id="de_description"
                v-model="form.de_description"
                rows="5"
                value=""
              >
              </textarea>
            </div>

            <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
            <p>
              <input
                                class="form-control-sm"
                name="de_emailContact"
                id="de_emailContact"
                v-model="form.de_emailContact"
                type="text"
                placeholder="Contact email"
              />
              <br>
              <input
                v-mask="'+(##) ###-###-####'"
                type="text"
                class="form-control-sm mt-1"
                name="num_contactPhone"
                id="num_contactPhone"
                v-model="form.num_contactPhone"
              />
              <br>
            </p>

            <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
            <input-tag v-model="form.tags" :limit="10"></input-tag>
          </div>
        </div>

        <div class="card email-rightbar">
          <div class="card-body">
            <b-tabs content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">History</span>
                </template>
                <div class="form-group">
                  <label >Enter your company description.</label>
                  <vue-editor id="history" v-model="form.history"></vue-editor>
                </div>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Industry</span>
                </template>
                <label >Enter a full description about the industry of your company.</label>
                <vue-editor id="industry" v-model="form.industry"></vue-editor>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Capabilities</span>
                </template>
                <label >Enter a full description about capabilities of your company.</label>
                <vue-editor id="capabilities" v-model="form.capabilities"></vue-editor>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Social Networks</span>
                </template>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group position-relative">
                      <label for="validationTooltipUsername">Facebook</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span id="validationTooltipUsernamePrepend" class="input-group-text">
                            <i class="fab fa-facebook-f"></i>
                          </span>
                        </div>
                        <input
                          id="validationTooltipUsername"
                          type="text"
                          class="form-control"
                          placeholder="Facebook profile link"
                          aria-describedby="validationTooltipUsernamePrepend"
                        />
                      </div>
                    </div>

                    <div class="form-group position-relative">
                      <label for="validationTooltipUsername">Twitter</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span id="validationTooltipUsernamePrepend" class="input-group-text">
                            <i class="fab fa-twitter"></i>
                          </span>
                        </div>
                        <input
                          id="validationTooltipUsername"
                          type="text"
                          class="form-control"
                          placeholder="Twitter profile link"
                          aria-describedby="validationTooltipUsernamePrepend"
                        />
                      </div>
                    </div>

                    <div class="form-group position-relative">
                      <label for="validationTooltipUsername">Instagram</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span id="validationTooltipUsernamePrepend" class="input-group-text">
                            <i class="fab fa-instagram"></i>
                          </span>
                        </div>
                        <input
                          id="validationTooltipUsername"
                          type="text"
                          class="form-control"
                          placeholder="Instagram profile link"
                          aria-describedby="validationTooltipUsernamePrepend"
                        />
                      </div>
                    </div>

                    <div class="form-group position-relative">
                      <label for="validationTooltipUsername">Whatsapp</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span id="validationTooltipUsernamePrepend" class="input-group-text">
                            <i class="fab fa-whatsapp"></i>
                          </span>
                        </div>
                        <input
                          id="validationTooltipUsername"
                          type="text"
                          class="form-control"
                          placeholder="Whatsapp company number"
                          aria-describedby="validationTooltipUsernamePrepend"
                        />
                      </div>
                    </div>

                    <div class="form-group position-relative">
                      <label for="validationTooltipUsername">LinkedIn</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span id="validationTooltipUsernamePrepend" class="input-group-text">
                            <i class="fab fa-linkedin-in"></i>
                          </span>
                        </div>
                        <input
                          id="validationTooltipUsername"
                          type="text"
                          class="form-control"
                          placeholder="LinkedIn profile link"
                          aria-describedby="validationTooltipUsernamePrepend"
                        />
                      </div>
                    </div>

                    <div class="form-group position-relative">
                      <label for="validationTooltipUsername">Youtube</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span id="validationTooltipUsernamePrepend" class="input-group-text">
                            <i class="fab fa-youtube"></i>
                          </span>
                        </div>
                        <input
                          id="validationTooltipUsername"
                          type="text"
                          class="form-control"
                          placeholder="Youtube profile link"
                          aria-describedby="validationTooltipUsernamePrepend"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style type="text/css">
  .vue-input-tag-wrapper .input-tag{
    color: #fff !important;
    background-color: #5664d2 !important;
    border-color: #5664d2 !important;

    display: inline-block !important;
    font-weight: 400 !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.05rem 0.1rem !important;
    font-size: 0.7875rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff;
    display: inline-block;
  }

  .mail-list a {
    display: block;
    color: #74788d;
    /* line-height: 24px; */
    padding: 2px 3px;
  }
  .input-group-text{
    background-color: white;
  }
</style>